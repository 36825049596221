import { Link } from "react-router-dom";
import { Box, Flex, Image, Text } from "rebass";
import facebook from "../res/facebook-brands.svg";
import googlePlayStore from "../res/google-play.svg";
import insta from "../res/instagram-brands.svg";
import iosAppStore from "../res/ios-app-store.svg";
import "./Main.css";

function Main() {
  return (
    <div className="app">
      <Flex
        sx={{
          flexDirection: "column",
          flex: 1,
          justifyContent: "space-around",
          marginBottom: "10vh",
        }}
      >
        <Image
          sx={{
            height: "15vw",
            width: "15vw",
          }}
          src={require("../res/circle_darkblue.png")}
        />
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Text
            sx={{
              marginBottom: "20px",
            }}
            fontWeight="bold"
            fontSize={[4, 5, 6]}
          >
            Learn music theory the easy way.
          </Text>
          <Text
            sx={{
              marginBottom: "10px",
            }}
            fontSize={[2, 3, 4]}
          >
            Fifth has an intuitive UI based entirely around the circle of
            fifths. Drill notes, intervals, chords, and modes in any key.
          </Text>
          <Text
            sx={{
              marginBottom: "10px",
            }}
            fontSize={[2, 3, 4]}
          >
            Set practice reminders to build the habit and pick up music theory
            quickly and easily.
          </Text>
          <Text
            sx={{
              marginBottom: "10px",
            }}
            fontSize={[2, 3, 4]}
          >
            Use explore mode to see what notes are in a given key, how to form
            specific chords from those notes, and how the attributes of a key
            fit together.
          </Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Text fontSize={[3, 4, 5]}>Stay in touch</Text>
            <Flex
              sx={{
                justifyContent: "space-evenly",
              }}
            >
              <Image
                sx={{
                  width: "50px",
                }}
                src={facebook}
              />
              <Image
                sx={{
                  width: "50px",
                }}
                src={insta}
              />
            </Flex>
          </Box>
          <Box>
            <Text fontSize={[3, 4, 5]}>Get fifth on the app store</Text>
            <Flex
              sx={{
                justifyContent: "space-evenly",
              }}
            >
              <Image
                sx={{
                  width: "50px",
                }}
                src={googlePlayStore}
              />
              <Image
                sx={{
                  width: "50px",
                }}
                src={iosAppStore}
              />
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <p className="privacy-policy">
        Ⓒ Copyright Dinky Beats LLC <span>{new Date().getFullYear()}</span>. All
        Rights Reserved.{" "}
        <Link className="privacy-policy-link" to="privacy">
          Privacy Policy
        </Link>
      </p>
    </div>
  );
}

export default Main;
