import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import Privacy from "./components/Privacy";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyADqwJ-vZfLn0AbHKRrRhAq85G7zNWC0Dg",
  authDomain: "dinky-beats.firebaseapp.com",
  projectId: "dinky-beats",
  storageBucket: "dinky-beats.appspot.com",
  messagingSenderId: "92092832091",
  appId: "1:92092832091:web:60542fd043622cc537627e",
  measurementId: "G-74H6Y5SQJ8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize React
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
