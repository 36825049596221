import { privacy } from "./privacyObj";
import "./Terms.css";

function Terms() {
  return (
    <div>
      <div dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
}

function createMarkup() {
  return { __html: privacy };
}

export default Terms;
